'use client';
import {
	Box,
	Container,
	Divider,
	Grid,
	List,
	ListItem,
	Typography,
} from '@mui/material';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';

import { useClientTranslation } from '@negotium/common';
import { IPagesBasicsShow, ISocialNetworksShow } from '@negotium/models';
import { useNotification } from '../ErrorContext';
import { getPageBasicByMultiplesTypes } from '../../app/lib/pagesBasic/actions';
import { getSocialNetworksPublic } from '../../app/lib/socialNetworks/actions';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import TelegramIcon from '@mui/icons-material/Telegram';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { FacebookSvg, InstagramSvg, WholesalerSvg } from '../Svgs';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import InfoIcon from '@mui/icons-material/Info';
import ThemeBasic from 'apps/customer-portal/utils/theme';
import Image from 'next/image';
type FooterProps = {
	lng: 'es' | 'en';
};

const ICON_SOCIAL_NETWORK: { [key: string]: JSX.Element } = {
	facebook: <FacebookSvg color={ThemeBasic.palette.secondary.main} />,
	whatsapp: <WhatsAppIcon color='secondary' fontSize='large' />,
	twitter: <XIcon color='secondary' fontSize='large' />,
	instagram: <InstagramSvg color={ThemeBasic.palette.secondary.main} />,
	youtube: <YouTubeIcon color='secondary' fontSize='large' />,
	linkedin: <LinkedInIcon color='secondary' fontSize='large' />,
	tiktok: <PlayCircleIcon color='secondary' fontSize='large' />,
	discord: <SmartToyIcon color='secondary' fontSize='large' />,
	telegram: <TelegramIcon color='secondary' fontSize='large' />,
	webSite: <TravelExploreIcon color='secondary' fontSize='large' />,
};

const Footer = ({ lng }: FooterProps) => {
	const { t } = useClientTranslation(lng, ['common', 'pagesBasic']);
	const { setErrorNotification } = useNotification();

	const [pageBasicColumn2, setPageBasicColumn2] = useState<
		Array<IPagesBasicsShow>
	>([]);
	const [pageBasicColumn3, setPageBasicColumn3] = useState<
		Array<IPagesBasicsShow>
	>([]);

	const [socialNetworks, setSocialNetworks] = useState<
		Array<ISocialNetworksShow>
	>([]);

	useEffect(() => {
		Promise.all([
			getPageBasicByMultiplesTypes({
				types: ['termsShipping', 'paymentTerms', 'warrantiesRefunds', 'faqs'],
			}),
			getPageBasicByMultiplesTypes({
				types: ['privacyTerms', 'termsAndConditions'],
			}),
			getSocialNetworksPublic(),
		])
			.then(
				([
					pageBasicColumn2Response,
					pageBasicColumn3Response,
					socialNetworksPublicResponse,
				]) => {
					setPageBasicColumn2(pageBasicColumn2Response.docs);
					setPageBasicColumn3(pageBasicColumn3Response.docs);
					setSocialNetworks(socialNetworksPublicResponse.docs);
				}
			)
			.catch((error) => setErrorNotification(error.message));
	}, []);

	return (
		<footer>
			<Container
				maxWidth={false}
				disableGutters
				sx={{
					background: (theme) => theme.palette.secondary?.main,
					backgroundImage: {
						md: `url('/static/footerdesktop.png')`,
						xs: `url('/static/footermovil.jpeg')`,
					},
					backgroundSize: { md: 'contain', xs: 'cover' },
					backgroundRepeat: 'no-repeat',
					backdropFilter: 'blur(10px)',
					backgroundPosition: { md: 'right top', xs: 'center bottom' },
					py: 8,
					minHeight: { xs: '100vh', sm: '156vh', md: 'auto' },
				}}
			>
				<Container maxWidth='xl'>
					<Grid container>
						<Grid xs={12} md={6.5}>
							<Grid
								container
								spacing={1}
								sx={{
									mb: 5,
								}}
							>
								<Grid item xs={12} sm={12}>
									<Box
										sx={{
											width: { xs: '40%', md: '20%' },
											padding: '5px 0px',
											display: 'flex',
											margin: {
												md: '0',
												xs: '0 ',
											},
										}}
									>
										<Link href={`/${lng}`} hrefLang={lng}>
											<Image
												src='/static/logoWhite667x218.png'
												width={667}
												height={128}
												alt={process.env.NEXT_PUBLIC_NAME || ''}
												style={{ width: '100%', height: 'auto' }}
											/>
										</Link>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Divider
										sx={{
											mt: 2,
											mb: 2,
											borderColor: (theme) => theme.palette.white?.main,
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<Link
										href={`/information/aboutUs`}
										style={{
											textDecoration: 'none',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													width: { md: '35px', xs: '22px' },
													height: { md: '35px', xs: '22px' },
													background: 'white',
													borderRadius: '50%',
												}}
											>
												<QuestionMarkIcon
													sx={{
														color: (theme) => theme.palette.secondary?.main,
														width: { md: '25px', xs: '15px' },
														height: { md: '25px', xs: '15px' },
													}}
												/>
											</Box>
											<Typography
												variant='TitleSection'
												color='white.main'
												fontSize='clamp(13px, 3vw,1.2rem)'
												ml={1}
												sx={{
													'&:hover': {
														textDecoration: 'underline',
													},
												}}
											>
												{t('whatIsRelsa')}
											</Typography>
										</Box>
									</Link>
								</Grid>
								<Grid item xs={12} md={6}>
									<Link
										href={`/information/wholesales`}
										style={{
											textDecoration: 'none',
										}}
									>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													width: { md: '35px', xs: '22px' },
													height: { md: '35px', xs: '22px' },
													background: 'white',
													borderRadius: '50%',
												}}
											>
												<WholesalerSvg
													width='14px'
													height='14px'
													color={ThemeBasic.palette.secondary?.main}
												/>
											</Box>
											<Typography
												variant='TitleSection'
												color='white.main'
												fontSize='clamp(13px, 3vw,1.2rem)'
												ml={{ md: 1, xs: 1.5 }}
												sx={{
													'&:hover': {
														textDecoration: 'underline',
													},
												}}
											>
												{t('wholeSaler')}
											</Typography>
										</Box>
									</Link>
								</Grid>
								<Grid item xs={12}>
									<Divider
										sx={{
											mt: 2,
											mb: 2,
											borderColor: (theme) => theme.palette.white?.main,
											width: { lg: '96%', xl: 'auto' },
										}}
									/>
								</Grid>

								<Grid item xs={12}>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<InfoIcon
											sx={{
												color: (theme) => theme.palette.white?.main,
												width: { md: '40px', xs: '25px' },
												height: { md: '40px', xs: '25px' },
											}}
										/>
										<Typography
											variant='TitleSection'
											color='white.main'
											ml={1}
											fontSize='clamp(13px, 3vw,1.2rem)'
										>
											{t('attentionClient')}
										</Typography>
									</Box>

									<Grid
										container
										sx={{
											width: { md: '87%', xs: '78%' },
											margin: 'auto',
										}}
									>
										{[...pageBasicColumn2, ...pageBasicColumn3].map((page) => (
											<Grid item xs={6} key={page.pagesBasicId}>
												<Link
													href={`/information/${page.pageBasicType}`}
													style={{
														textDecoration: 'none',
													}}
												>
													<Typography
														variant='BodySection'
														fontSize='clamp(10px,3vw,13px)'
														// textTransform='uppercase'
														lineHeight='1rem'
														fontWeight='bold'
														color='white.main'
														sx={{
															'&:hover': {
																textDecoration: 'underline',
															},
														}}
													>
														{t(page.pageBasicType, { ns: 'pagesBasic' })}
													</Typography>
												</Link>
											</Grid>
										))}
									</Grid>
								</Grid>

								<Grid item xs={12}>
									<Divider
										sx={{
											width: { md: '95%', xs: '90%', lg: '96%', xl: 'auto' },
											marginLeft: { xs: 'auto', lg: '0' },
											marginRight: { xs: '0', lg: 'auto' },
											borderColor: (theme) => theme.palette.white?.main,
										}}
									/>
									<Box width={{ md: '87%', xs: '78%' }} margin='auto'>
										<Box display='flex' alignItems={'center'}>
											<Typography
												width='48%'
												variant='TitleSection'
												lineHeight='1rem'
												fontSize='clamp(13px, 3vw,1.2rem)'
												fontWeight='bold'
												color='white.main'
											>
												{t('followUs')}
											</Typography>

											<List
												sx={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
												{socialNetworks.map((social) => (
													<ListItem
														key={social.socialNetworksId}
														alignItems='center'
														sx={{
															justifyContent: {
																sm: 'flex-start',
																xs: 'center',
															},
															width: 'max-content',
															// marginBottom: 1,
															p: 1,
														}}
													>
														<Box
															sx={{
																width: { md: '38px', xs: '20px' },
																height: { md: '38px', xs: '20px' },
																borderRadius: '50%',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																background: (theme) =>
																	theme.palette.white?.main || 'white',
															}}
														>
															<Link
																target='_blank'
																title={social.name}
																href={social.url}
																style={{
																	textDecoration: 'none',
																	display: 'flex',
																	justifyContent: 'center',
																	alignItems: 'center',
																}}
															>
																{ICON_SOCIAL_NETWORK[social.name]}
															</Link>
														</Box>
													</ListItem>
												))}
											</List>
										</Box>
									</Box>
									<Divider
										sx={{
											width: { md: '95%', xs: '90%', lg: '90%', xl: 'auto' },
											marginLeft: { md: '0', xs: 'auto' },
											marginRight: {
												md: '0',
												xs: '25%',
												lg: 'auto',
											},
											borderColor: (theme) => theme.palette.white?.main,
										}}
									/>
								</Grid>
								{/* <Grid item xs={12}>
									<Typography
										variant='BodySection'
										fontSize='clamp(0.9rem,3vw,1.1rem)'
										textAlign='center'
										color='white.main'
										lineHeight={{
											xs: '1.6rem',
											md: '2.6rem',
										}}
										sx={{
											a: {
												color: (theme) => theme.palette.white?.main,
											},
										}}
									>
										<span>{` ${t(
											'allRights'
										)} _ © ${new Date().getFullYear()} _ ${t(
											'developBy'
										)} `}</span>
										<Link
											href='https://www.futurasit.com'
											passHref
											style={{
												textDecoration: 'none',
											}}
										>
											Futura Sit
										</Link>
									</Typography>
								</Grid> */}
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Container>
		</footer>
	);
};

export default Footer;

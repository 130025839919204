'use client';
import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Typography,
	Slider,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	FormControl,
	FormGroup,
	FormControlLabel,
	Rating,
	Grid,
	useMediaQuery,
	useTheme,
	DialogContent,
	DialogActions,
	DialogTitle,
	Dialog,
	Slide,
	TextField,
} from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { ICategoriesShow, IDictionaryCatalog } from '@negotium/models';
import { groupListCategories } from '../../app/lib/categories/actions';
import { useSearchParams } from 'next/navigation';
import { LoadingRectangle } from '../Skeleton';
import { useCatalogContext } from '../ContextCatalog';
import { getMinusAndMajorPriceProduct } from '@negotium/libCustomer';
import { formatCurrency } from 'apps/customer-portal/utils/numWithComma';
import {
	ArrowSvg,
	CategoriesSvg,
	CloseIconSvg,
	PriceSvg,
	StarOutlined,
	StarSolid,
} from '../Svgs';
import ThemeBasic from 'apps/customer-portal/utils/theme';
import { TransitionProps } from '@mui/material/transitions';
import { useContextGeneral } from '../ContextGeneral';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

type FilterCategoryProductProps = {
	lng: 'es' | 'en';
};

function FilterCategoryProduct({ lng }: FilterCategoryProductProps) {
	const { t } = useClientTranslation(lng, ['catalog']);
	const {
		categoriesSelected,
		handlerSelected,
		clearSelected,
		// clearSelectedAndRouter,
		priceRange,
		setPriceRange,
		setExecuteSearch,
		ratings,
		setRatings,
	} = useCatalogContext();

	const { handlerOpenMobileFilter, openMobileFilter } = useContextGeneral();
	const [categories, setCategories] = useState<Array<ICategoriesShow>>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const searchParams = useSearchParams();
	const [expanded, setExpanded] = useState<Array<string>>([
		'categories',
		'price',
		'ratings',
	]);
	const [dictionary, setDictionary] = useState<IDictionaryCatalog | null>(null);
	const RATINGS_LIST = [5, 4, 3, 2, 1];
	const [marks, setMarks] = useState<
		Array<{
			value: number;
			label: string;
		}>
	>([]);

	const theme = useTheme();

	const isMobile = useMediaQuery('(max-width:768px)');

	const handlePriceChange = (event: Event, newValue: number | number[]) => {
		setPriceRange(newValue as number[]);
	};

	const handlerInputPriceChange = (value: string | number, max = false) => {
		if (max) {
			setPriceRange([priceRange[0], Number(value)]);
		} else {
			setPriceRange([Number(value), priceRange[1]]);
		}
	};

	useEffect(() => {
		if (loading) {
			Promise.all([groupListCategories({}), getMinusAndMajorPriceProduct()])
				.then(([categoriesResponse, priceProductResponse]) => {
					if (categoriesResponse?.docs) {
						setCategories([...categoriesResponse.docs]);
					}
					if (priceProductResponse.data) {
						setDictionary(priceProductResponse.data);
						setPriceRange([
							priceProductResponse.data.price.min,
							priceProductResponse.data.price.max,
						]);
						setMarks([
							{
								value: priceProductResponse.data.price.min,
								label: formatCurrency(priceProductResponse.data.price.min),
							},
							{
								value: priceProductResponse.data.price.max,
								label: formatCurrency(priceProductResponse.data.price.max),
							},
						]);
					}
					setLoading(false);
				})
				.catch(() => {
					setLoading(false);
				});
		}
	}, [loading]);

	useEffect(() => {
		if (searchParams.size > 0) {
			const categoriesId: string | null = searchParams.get('categoriesId');
			if (categoriesId) {
				handlerSelected(categoriesId);
			} else {
				clearSelected();
			}
		}
	}, [searchParams.size]);

	const handleToggle = (value: string) => () => {
		handlerSelected(value);
	};

	if (loading) {
		return <LoadingRectangle width='100%' height={'100px'} />;
	}

	if (!loading && categories.length === 0) {
		return null;
	}

	function valuetext(value: number) {
		return `${formatCurrency(value)}`;
	}

	function handlerResetRangePrice() {
		setPriceRange([
			dictionary?.price.min || 0,
			dictionary?.price.max || 999999,
		]);
		setExecuteSearch(true);
	}
	function handlerResetRating() {
		setRatings(null);
		setExecuteSearch(true);
	}

	const handleCheckboxChange = (ratingValue: number) => {
		if (ratings) {
			setRatings({ ...ratings, [ratingValue]: !ratings[ratingValue] });
		} else {
			setRatings({ [ratingValue]: true });
		}
	};

	function handleChangeExpanded(type: string) {
		if (expanded.includes(type)) {
			setExpanded([...expanded.filter((item) => item !== type)]);
		} else {
			setExpanded([...expanded, type]);
		}
	}

	function renderCategories(
		categories: Array<ICategoriesShow>,
		levelOne: boolean
	) {
		return (
			<List dense sx={{ width: '100%' }}>
				{categories.map((category) => {
					const labelId = `checkbox-list-secondary-label-${category.categoriesId}`;
					return levelOne ? (
						<ListItem key={category.categoriesId} disablePadding>
							<Accordion
								style={{
									borderRadius: 0,
									boxShadow: 'none',
								}}
								sx={{
									width: '100%',
									background: 'white',
								}}
							>
								<AccordionSummary
									expandIcon={
										<Box
											sx={{
												width: '15px',
												height: '15px',
											}}
										>
											<ArrowSvg />
										</Box>
									}
									aria-controls='panel1a-content'
									id={labelId}
									sx={{
										padding: { xs: '0 16px 0px 3px', md: '0 16px' },

										m: '0 !important',
										'& .Mui-expanded ': {
											minHeight: 'auto',
										},
										'& .MuiAccordionSummary-content ': {
											m: '0 !important',
										},
										border: { xs: '1px solid black', md: 'none' },
										borderRadius: { xs: '10px', md: 'none' },
										marginBottom: '5px !important',
									}}
								>
									<Box display='flex' alignItems='center'>
										<Checkbox
											color='secondary'
											edge='end'
											onChange={handleToggle(category.categoriesId)}
											checked={categoriesSelected.includes(
												category.categoriesId
											)}
											inputProps={{ 'aria-labelledby': labelId }}
											sx={{
												color: (theme) => theme.palette.secondary?.main,
												'&.Mui-checked': {
													color: (theme) => theme.palette.secondary?.main,
												},
											}}
										/>

										<Typography
											variant='ExplicationTextBold'
											color='secondary.main'
											lineHeight={1}
											marginLeft={1}
											textTransform={levelOne ? { md: 'uppercase' } : 'none'}
										>
											{category.name[lng as 'es' | 'en']}
										</Typography>
									</Box>
								</AccordionSummary>
								{category.child?.length > 0 && (
									<AccordionDetails sx={{ p: 0 }}>
										<Box
											sx={{
												pl: { md: 3, xs: 0 },
											}}
										>
											{renderCategories(category.child, false)}
										</Box>
									</AccordionDetails>
								)}
							</Accordion>
						</ListItem>
					) : (
						<>
							<ListItem key={category.categoriesId} disablePadding>
								<ListItemButton
									sx={{
										py: 0,
									}}
									onClick={handleToggle(category.categoriesId)}
								>
									<ListItemIcon>
										<Checkbox
											color='secondary'
											edge='end'
											onChange={handleToggle(category.categoriesId)}
											checked={categoriesSelected.includes(
												category.categoriesId
											)}
											inputProps={{ 'aria-labelledby': labelId }}
											sx={{
												color: (theme) => theme.palette.secondary?.main,
												'&.Mui-checked': {
													color: (theme) => theme.palette.secondary?.main,
												},
											}}
										/>
									</ListItemIcon>
									<ListItemText
										id={labelId}
										primary={category.name[lng as 'es' | 'en']}
										primaryTypographyProps={{
											variant: 'ExplicationTextBold',
											color: 'secondary.main',
											lineHeight: 1,
											fontWeight: 'bold',
											fontSize: '14px',
											textTransform: levelOne ? 'uppercase' : 'none',
										}}
									/>
								</ListItemButton>
							</ListItem>
							{category.child?.length > 0 && (
								<Box
									sx={{
										pl: 5,
									}}
								>
									{renderCategories(category.child, false)}
								</Box>
							)}
						</>
					);
				})}
			</List>
		);
	}

	//

	const RenderFilter = (
		<Box>
			<Paper
				elevation={isMobile ? 0 : 1}
				sx={{
					width: '100%',
					background: (theme) => theme.palette.white?.main || 'white',
					borderRadius: 5,
					mb: 2,
					mt: 1,
				}}
			>
				<Accordion
					elevation={isMobile ? 0 : 1}
					style={{
						borderRadius: isMobile ? 1 : 20,
					}}
					expanded={expanded.includes('categories')}
					onChange={() => handleChangeExpanded('categories')}
					sx={{
						width: '100%',
						background: (theme) => theme.palette.white?.main || 'white',
						mb: { md: 1, xs: 0 },
						'&::before': {
							display: 'none',
						},
					}}
				>
					<AccordionSummary
						expandIcon={
							<Box
								sx={{
									width: '15px',
									height: '15px',
								}}
							>
								<ArrowSvg />
							</Box>
						}
						aria-controls='panel1-content'
						id='panel1-header'
						sx={{
							width: '100%',
							background: (theme) => theme.palette.secondary?.main,
							borderRadius: isMobile ? '10px' : 5,
							'.Mui-expanded': {
								margin: '10px 0 !important',
								minHeight: 'auto !important',
							},
							'&.MuiAccordionSummary-root': {
								minHeight: 'auto !important',
							},
						}}
					>
						<CategoriesSvg />
						<Typography
							variant='ExplicationTextBold'
							color='white.main'
							lineHeight={1}
							marginLeft={1}
							textTransform='uppercase'
							fontSize='clamp(13px,3vw,1.1rem)'
						>
							{`${t('category')}`}
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						sx={{
							border: '20px !important',
							px: 0,
							pb: 0,
						}}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Box
									sx={{
										p: 0,
										pb: { md: 2, xs: 0 },
										maxHeight: { md: 'calc(55vh - 10px)', xs: 'auto' },
										overflowY: 'scroll',
										overflowX: 'hidden',
									}}
								>
									{!loading &&
										categories &&
										categories.length > 0 &&
										renderCategories(categories, true)}
									{loading &&
										[...Array(3).keys()].map((item, index) => (
											<Box
												key={`${item}-${index}`}
												marginTop={1}
												display='flex'
												justifyContent='space-between'
											>
												<LoadingRectangle width={'80%'} height='20px' />
												<LoadingRectangle width={'10%'} height='20px' />
											</Box>
										))}
								</Box>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</Paper>

			{dictionary?.price && (
				<Accordion
					elevation={isMobile ? 0 : 1}
					style={{
						borderRadius: isMobile ? 1 : 20,
					}}
					expanded={expanded.includes('price')}
					onChange={() => handleChangeExpanded('price')}
					sx={{
						width: '100%',
						background: (theme) => theme.palette.white?.main || 'white',
						mb: 2,
						'&::before': {
							display: 'none',
						},
					}}
				>
					<AccordionSummary
						expandIcon={
							<Box
								sx={{
									width: '15px',
									height: '15px',
								}}
							>
								<ArrowSvg />
							</Box>
						}
						aria-controls='panel1-content'
						id='panel1-header'
						sx={{
							width: '100%',
							background: (theme) => theme.palette.secondary?.main,
							borderRadius: isMobile ? '10px' : 5,
							'.Mui-expanded': {
								margin: '10px 0 !important',
								minHeight: 'auto !important',
							},
							'&.MuiAccordionSummary-root': {
								minHeight: 'auto !important',
								paddingRight: '18px !important',
							},
						}}
					>
						<PriceSvg />
						<Typography
							variant='ExplicationTextBold'
							color='white.main'
							lineHeight={1}
							marginLeft={2}
							textTransform='uppercase'
							fontSize='clamp(13px,3vw,1.1rem)'
						>
							{`${t('price')}`}
						</Typography>
					</AccordionSummary>
					<AccordionDetails
						sx={{
							border: '20px !important',
							p: {
								xs: 0,
								md: '8px 16px 16px',
							},
						}}
					>
						<Box
							sx={{
								mt: {
									xs: 1,
									md: 0,
								},
								pt: { md: 4, xs: 2 },
								pb: { md: 2, xs: 2 },
								px: { md: 5, xs: 2 },
								borderRadius: { md: '0', xs: '10px' },
								border: {
									xs: `solid 1px ${ThemeBasic.palette.secondary?.main}`,
									md: 'none',
								},
							}}
						>
							<Box
								sx={{
									display: {
										xs: 'flex',
										md: 'none',
									},
									mb: 1,
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<FormGroup
											sx={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												width: '100%',
											}}
										>
											<Typography
												variant='ExplicationTextBold'
												fontSize='clamp(13px,3vw,1.1rem)'
											>
												{t('priceMin')}
											</Typography>
											<TextField
												inputProps={{
													min: dictionary?.price.min || 0,
													max: dictionary?.price.max || 999999,
												}}
												type='number'
												value={priceRange[0]}
												onChange={(e) =>
													handlerInputPriceChange(e.target.value, false)
												}
												color='secondary'
												sx={{
													mt: 0.7,
													width: '100%',

													borderRadius: '10px',
													'& .MuiOutlinedInput-notchedOutline': {
														borderColor: (theme) =>
															theme.palette.secondary.main,
														borderWidth: 1,
													},
													'& .MuiInputBase-input': {
														py: `8px !important`,
														textAlign: 'center',
														color: (theme) => theme.palette.secondary.main,
													},
													'& > *': {
														borderRadius: `10px !important`,
													},
												}}
											/>
										</FormGroup>
									</Grid>
									<Grid item xs={6}>
										<FormGroup
											sx={{
												display: 'flex',
												flexDirection: 'column',
												alignItems: 'center',
												width: '100%',
											}}
										>
											<Typography
												variant='ExplicationTextBold'
												fontSize='clamp(13px,3vw,1.1rem)'
											>
												{t('priceMax')}
											</Typography>
											<TextField
												value={priceRange[1]}
												onChange={(e) =>
													handlerInputPriceChange(e.target.value, true)
												}
												inputProps={{
													min: dictionary?.price.min || 0,
													max: dictionary?.price.max || 999999,
												}}
												type='number'
												sx={{
													width: '100%',

													mt: 0.7,
													borderRadius: '10px',
													'& .MuiOutlinedInput-notchedOutline': {
														borderColor: (theme) =>
															theme.palette.secondary.main,
														borderWidth: 1,
													},
													'& .MuiInputBase-input': {
														py: `8px !important`,
														textAlign: 'center',
														color: (theme) => theme.palette.secondary.main,
													},
													'& > *': {
														borderRadius: `10px !important`,
													},
												}}
											/>
										</FormGroup>
									</Grid>
								</Grid>
							</Box>
							<Slider
								sx={{
									display: {
										xs: 'none',
										md: 'block',
									},
								}}
								color='secondary'
								value={priceRange}
								onChange={handlePriceChange}
								valueLabelDisplay='auto'
								min={dictionary.price.min}
								max={dictionary.price.max}
								valueLabelFormat={(value) => `${formatCurrency(value)}`}
								getAriaValueText={valuetext}
								aria-labelledby='price-slider'
								marks={marks}
							/>
						</Box>
						<Box
							justifyContent='space-evenly'
							alignItems='center'
							sx={{
								p: 1,
								display: {
									xs: 'none',
									md: 'flex',
								},
							}}
						>
							<Button
								color='warning'
								size='medium'
								variant='contained'
								title={t('search')}
								// disabled={processing || isProcessing}
								onClick={() => handlerResetRangePrice()}
							>
								{t('clean')}
							</Button>
							<Button
								color='secondary'
								size='medium'
								variant='contained'
								title={t('search')}
								// disabled={processing || isProcessing}
								onClick={() => setExecuteSearch(true)}
							>
								{t('search')}
							</Button>
						</Box>
					</AccordionDetails>
				</Accordion>
			)}

			<Accordion
				elevation={isMobile ? 0 : 1}
				style={{
					borderRadius: isMobile ? 1 : 20,
				}}
				expanded={expanded.includes('ratings')}
				onChange={() => handleChangeExpanded('ratings')}
				sx={{
					width: '100%',
					background: (theme) => theme.palette.white?.main || 'white',
					'&::before': {
						display: 'none',
					},
				}}
			>
				<AccordionSummary
					expandIcon={
						<Box
							sx={{
								width: '15px',
								height: '15px',
							}}
						>
							<ArrowSvg />
						</Box>
					}
					aria-controls='panel1-content'
					id='panel1-header'
					sx={{
						width: '100%',
						background: (theme) => theme.palette.secondary?.main,
						borderRadius: isMobile ? '10px' : 5,

						'.Mui-expanded': {
							margin: '10px 0 !important',
							minHeight: 'auto !important',
						},
						'&.MuiAccordionSummary-root': {
							minHeight: 'auto !important',
							paddingRight: '18px !important',
						},
					}}
				>
					<StarSolid />
					<Typography
						variant='ExplicationTextBold'
						fontSize='clamp(13px,3vw,1.1rem)'
						textTransform='uppercase'
						color='white.main'
						lineHeight={1}
						marginLeft={2}
					>
						{`${t('score')}`}
					</Typography>
				</AccordionSummary>
				<AccordionDetails
					sx={{
						borderBottomLeftRadius: '20px !important',
						borderBottomRightRadius: '20px !important',
						p: {
							xs: 0,
							md: '8px 16px 16px',
						},
					}}
				>
					<Box
						sx={{
							pt: { md: 2 },
							pb: { md: 2 },
							px: { md: 5 },
						}}
					>
						<FormControl component='fieldset' fullWidth>
							<FormGroup
								row={isMobile ? false : true}
								sx={{
									width: '100%',
								}}
							>
								{RATINGS_LIST.map((ratingValue) => (
									<FormControlLabel
										sx={{
											border: {
												xs: `solid 1px ${ThemeBasic.palette.secondary?.main}`,
												md: 'none',
											},
											borderRadius: {
												xs: '10px',
												md: '0',
											},
											width: '100%',
											marginLeft: {
												xs: 0,
												md: '-11px',
											},
											mt: {
												xs: 1,
												md: 0,
											},
										}}
										key={ratingValue}
										control={
											<Checkbox
												checked={ratings?.[Number(ratingValue)] as boolean}
												onChange={() =>
													handleCheckboxChange(Number(ratingValue))
												}
												value={ratingValue}
												sx={{
													color: (theme) => theme.palette.secondary?.main,
													'&.Mui-checked': {
														color: (theme) => theme.palette.secondary?.main,
													},
												}}
											/>
										}
										label={
											<Rating
												value={Number(ratingValue)}
												precision={0.5}
												size='medium'
												readOnly
												icon={
													<StarSolid
														color={ThemeBasic.palette.secondary.main}
													/>
												}
												emptyIcon={
													<StarOutlined
														color={ThemeBasic.palette.secondary.main}
													/>
												}
											/>
										}
									/>
								))}
							</FormGroup>
						</FormControl>
					</Box>
					<Box
						justifyContent='space-evenly'
						alignItems='center'
						sx={{
							p: 1,
							display: {
								xs: 'none',
								md: 'flex',
							},
						}}
					>
						<Button
							color='warning'
							size='medium'
							variant='contained'
							title={t('search')}
							// disabled={processing || isProcessing}
							onClick={() => handlerResetRating()}
						>
							{t('clean')}
						</Button>
						<Button
							color='secondary'
							size='medium'
							variant='contained'
							title={t('search')}
							// disabled={processing || isProcessing}
							onClick={() => setExecuteSearch(true)}
						>
							{t('search')}
						</Button>
					</Box>
				</AccordionDetails>
			</Accordion>
		</Box>
	);

	const FilterDesktop = (
		<Box
			sx={{
				display: { md: 'block', xs: 'none' },
			}}
		>
			{RenderFilter}
		</Box>
	);
	const FilterMobile = (
		<Box
			sx={{
				display: { md: 'none', xs: 'block' },
			}}
		>
			{openMobileFilter && (
				<Dialog
					open={openMobileFilter}
					TransitionComponent={Transition}
					keepMounted
					onClose={() => handlerOpenMobileFilter()}
					aria-describedby='alert-dialog-slide-description'
					PaperProps={{
						sx: {
							backgroundColor: theme.palette.white?.main || 'white',
							borderRadius: 3,
							m: 1.5,
							maxHeight: 'calc(100% - 16px)',
							maxWidth: 'calc(100% - 16px)',
						},
						style: {
							width: 'calc(100% - 16px)',
							maxWidth: 'none',
						},
					}}
					maxWidth={false}
					fullScreen
					slotProps={{
						backdrop: {
							sx: {
								background: (theme) => theme.palette.white?.light,
								p: 2,
								backdropFilter: 'blur(10px)',
							},
						},
					}}
				>
					<DialogTitle
						sx={{
							background: (theme) => theme.palette.secondary.main,
						}}
					>
						<Grid container>
							<Grid item xs={9}>
								<Typography
									variant='ExplicationTextBold'
									fontSize='1.2rem'
									color='white.main'
								>
									{t('filterBy')}
								</Typography>
							</Grid>
							<Grid xs={3} item display='flex' justifyContent='flex-end'>
								<Button
									onClick={() => handlerOpenMobileFilter()}
									variant='text'
									color='secondary'
									sx={{
										p: 0,
										minWidth: '16px',
										height: '16px',
										borderRadius: '50%',
										mt: 1,
									}}
								>
									<CloseIconSvg />
								</Button>
							</Grid>
						</Grid>
					</DialogTitle>
					<DialogContent
						sx={{
							px: { md: '3px', xs: '10px' },
						}}
					>
						<Grid
							container
							sx={{
								my: 1,
							}}
						>
							<Grid item xs={12}>
								{RenderFilter}
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions
						sx={{
							display: 'flex',
							justifyContent: 'space-around',
							alignItems: 'center',
							p: 2,
							// background: (theme) => theme.palette.white?.light,
						}}
					>
						<Button
							onClick={() => handlerOpenMobileFilter()}
							variant='contained'
							color='error'
							sx={{
								width: '45%',
								textTransform: 'capitalize',
							}}
						>
							{t('cancel')}
						</Button>
						<Button
							onClick={() => handlerOpenMobileFilter()}
							variant='contained'
							color='secondary'
							sx={{
								width: '45%',
								textTransform: 'capitalize',
							}}
						>
							{t('apply')}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Box>
	);

	return (
		<Box
			sx={{
				width: '100%',
				borderRadius: 5,
				position: 'relative',
			}}
		>
			{FilterDesktop}
			{FilterMobile}
		</Box>
	);
}
export default FilterCategoryProduct;

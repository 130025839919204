'use client';
import { useClientTranslation } from '@negotium/common';
import {
	Alert,
	AlertTitle,
	Box,
	Container,
	Grid,
	List,
	Paper,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IRecipients } from '@negotium/models';
import {
	LoadingRectangle,
	RecipientsCardShow,
	RecipientsWidget,
	useNotification,
} from '@negotium/componentsCustomer';
import { getRecipients } from '@negotium/libCustomer';
import { RecipientsSvg } from '../Svgs';
import ThemeBasic from 'apps/customer-portal/utils/theme';

type RecipientsProps = {
	lng: 'es' | 'en';
};

export default function Recipients({ lng }: RecipientsProps) {
	const { t } = useClientTranslation(lng, ['recipients']);

	const { setErrorNotification } = useNotification();
	const [data, setData] = useState<Array<IRecipients> | []>([]);
	const [loadingRecipients, setLoadingRecipients] = useState<boolean>(true);

	useEffect(() => {
		if (loadingRecipients) {
			getRecipients(10, 0)
				.then((result) => {
					setData([...result.docs]);
					setLoadingRecipients(false);
				})
				.catch((err) => {
					setErrorNotification(err.message);
					setLoadingRecipients(false);
				});
		}
	}, [loadingRecipients]);

	if (loadingRecipients) {
		return (
			<Container
				maxWidth={false}
				sx={{
					py: 4,
				}}
			>
				<Container maxWidth='xl'>
					<Grid container spacing={2}>
						<Grid item xs={8}>
							<LoadingRectangle width={'80%'} height='5vh' />
						</Grid>
						<Grid item xs={4}>
							<LoadingRectangle width={'100%'} height='5vh' />
						</Grid>
						{[...Array(3).keys()].map((item) => (
							<Grid item xs={12} key={item}>
								<LoadingRectangle width={'100%'} height='20vh' />
							</Grid>
						))}
					</Grid>
				</Container>
			</Container>
		);
	}

	return (
		<Grid container spacing={2}>
			<Grid
				item
				xs={12}
				sm={8}
				display={{
					sm: 'block',
					xs: 'none',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						ml: 1,
					}}
				>
					<RecipientsSvg color={ThemeBasic.palette.secondary.main || ''} />
					<Typography variant='TitleSection' ml={1}>
						{t('myRecipients')}
					</Typography>
				</Box>
			</Grid>
			<Grid
				item
				xs={12}
				sm={4}
				justifyContent={{ md: 'flex-end', xs: 'center' }}
				display='flex'
			>
				<RecipientsWidget
					type='Add'
					lng={lng}
					onCallBack={() => setLoadingRecipients(!loadingRecipients)}
				/>
			</Grid>
			<Grid item xs={12}>
				<Paper
					elevation={2}
					sx={{
						mt: 2,
						p: { md: 4, xs: 1 },
						borderRadius: 5,
						height: { md: '70vh' },
						overflow: { md: 'auto' },
					}}
				>
					<Container maxWidth='xl' disableGutters>
						<Grid container spacing={2}>
							{data.length === 0 && !loadingRecipients && (
								<Grid item xs={12}>
									<Alert
										sx={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'column',
											svg: { width: '3rem', height: '3rem' },
											borderRadius: 5,
										}}
										title='test'
										color='info'
										severity='info'
									>
										<AlertTitle
											sx={{
												display: 'flex',
												justifyContent: 'center',
											}}
										>
											{t('noElement')}
										</AlertTitle>
										<Box padding='1rem'>
											<RecipientsWidget
												type='Add'
												lng={lng}
												onCallBack={() =>
													setLoadingRecipients(!loadingRecipients)
												}
											/>
										</Box>
									</Alert>
								</Grid>
							)}

							{data.length > 0 && !loadingRecipients && (
								<Grid item xs={12}>
									<List>
										{data.map((item) => (
											<RecipientsCardShow
												key={item.recipientId}
												onCallBack={() =>
													setLoadingRecipients(!loadingRecipients)
												}
												lng={lng}
												recipient={item}
											/>
										))}
									</List>
								</Grid>
							)}
						</Grid>
					</Container>
				</Paper>
			</Grid>
		</Grid>
	);
}

'use client';
import { ORDER_STATUS, useClientTranslation } from '@negotium/common';
import {
	Box,
	Button,
	CircularProgress,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	Grid,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { IOrderShow } from '@negotium/models';
import {
	LoadingRectangle,
	useNotification,
} from '@negotium/componentsCustomer';
import CancelIcon from '@mui/icons-material/Cancel';

import { cancelOrder, getPaidOrdersByAuthUser } from '@negotium/libCustomer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { formatCurrency } from '../../utils/numWithComma';
import Link from 'next/link';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

import { CartShop, UserSvg, WholesalerSvg } from '../Svgs';
import ThemeBasic from '../../utils/theme';

type OrderHistoryProps = {
	lng: 'es' | 'en';
};

export default function OrderHistory({ lng }: OrderHistoryProps) {
	const { t } = useClientTranslation(lng, ['orders', 'common']);

	const { setErrorNotification, setSuccessNotification } = useNotification();
	const [loading, setLoading] = useState<boolean>(true);
	const [ordersHistory, setOrdersHistory] = useState<Array<IOrderShow>>([]);
	const [executing, setExecuting] = useState<boolean>(false);

	const [openCancelOrder, setOpenCancelOrder] = useState<string>('');
	const [cancellationReason, setCancellationReason] = useState('');

	useEffect(() => {
		if (loading) {
			getPaidOrdersByAuthUser()
				.then((result) => {
					setOrdersHistory([...result.docs]);
					setLoading(false);
				})
				.catch((err) => {
					setErrorNotification(err.message);
					setLoading(false);
				});
		}
	}, [loading]);
	const handleCancelOrder = async (): Promise<void> => {
		setExecuting(true);
		cancelOrder(openCancelOrder, cancellationReason)
			.then(() => {
				setLoading(true);
				setSuccessNotification(t('orderCancelledSuccessfully'));
				setOpenCancelOrder('');
				setExecuting(false);
			})
			.catch((err) => {
				setExecuting(false);

				setErrorNotification(t('orderCancelError'));
			})
			.finally(() => setCancellationReason(''));
	};

	return (
		<Grid container spacing={2}>
			<Grid
				item
				xs={12}
				display={{
					md: 'block',
					xs: 'none',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						ml: 1,
					}}
				>
					<CartShop color={ThemeBasic.palette.secondary.main || ''} />
					<Typography ml={1} variant='TitleSection'>
						{t('orderHistory')}
					</Typography>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Paper
					elevation={2}
					sx={{
						mt: 2,
						p: { md: 4, xs: 1 },
						borderRadius: 5,
						height: { md: '70vh' },
						overflow: { md: 'auto' },
					}}
				>
					<Container maxWidth='xl' disableGutters>
						<Grid container spacing={2}>
							{loading &&
								[...Array(3).keys()].map((item) => (
									<Grid item xs={12} md={4} key={item}>
										<LoadingRectangle width={'100%'} />
									</Grid>
								))}

							{!loading && (
								<Grid item xs={12}>
									<TableContainer
										sx={{
											background: 'white',
											borderRadius: 5,
											height: { md: 'calc(70vh - 64px)' },
											overflow: { md: 'auto' },
										}}
									>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell align='center'>
														<Typography
															variant='BodySection'
															fontSize='1rem'
															fontWeight='bold'
															textTransform='uppercase'
														>
															{t('orderNumber')}
														</Typography>
													</TableCell>
													<TableCell align='center'>
														<Typography
															variant='BodySection'
															fontSize='1rem'
															fontWeight='bold'
															textTransform='uppercase'
														>
															{t('orderDate')}
														</Typography>
													</TableCell>
													<TableCell align='center'>
														<Typography
															variant='BodySection'
															fontSize='1rem'
															fontWeight='bold'
															textTransform='uppercase'
														>
															{t('orderStatus')}
														</Typography>
													</TableCell>
													<TableCell align='center'>
														<Typography
															variant='BodySection'
															fontSize='1rem'
															fontWeight='bold'
															textTransform='uppercase'
														>
															{t('orderTotal')}
														</Typography>
													</TableCell>
													<TableCell align='center'>
														<Typography
															variant='BodySection'
															fontSize='1rem'
															fontWeight='bold'
															textTransform='uppercase'
														>
															{t('actions', { ns: 'common' })}
														</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{ordersHistory.length === 0 && (
													<TableRow
														sx={{
															background: '#f6f6f6',
														}}
													>
														<TableCell colSpan={5}>
															<Box
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																	ml: 1,
																}}
															>
																<InfoIcon
																	fontSize='medium'
																	color='secondary'
																	sx={{ marginRight: '5px' }}
																/>
																<Typography
																	variant='BodySection'
																	fontSize='14px'
																>
																	{t('userAuthNotOrders')}
																</Typography>
															</Box>
														</TableCell>
													</TableRow>
												)}
												{ordersHistory.length > 0 &&
													ordersHistory.map((order) => (
														<TableRow key={order.orderId}>
															<TableCell align='center'>
																<Box
																	sx={{
																		display: 'flex',
																		alignItems: 'center',
																	}}
																>
																	{order.isWholesaler ? (
																		<WholesalerSvg
																			color={ThemeBasic.palette.secondary.main}
																		/>
																	) : (
																		<UserSvg
																			color={ThemeBasic.palette.secondary.main}
																		/>
																	)}
																	<Typography
																		variant='BodySection'
																		fontSize='14px'
																		fontWeight='500'
																		textTransform='uppercase'
																		ml={1}
																		mt={1}
																	>
																		{order.consecutiveNumber}
																	</Typography>
																</Box>
															</TableCell>
															<TableCell align='center'>
																<Typography
																	variant='BodySection'
																	fontSize='14px'
																	fontWeight='500'
																	textTransform='uppercase'
																>
																	{dayjs(order.createdAt)
																		.locale(lng)
																		.format('DD, MMMM YYYY hh:mm a')}
																</Typography>
															</TableCell>
															<TableCell align='center'>
																<Typography
																	variant='BodySection'
																	fontSize='14px'
																	fontWeight='500'
																	textTransform='uppercase'
																>
																	{ORDER_STATUS[order.status]?.es || ''}
																</Typography>
															</TableCell>
															<TableCell align='center'>
																<Typography
																	variant='BodySection'
																	fontSize='14px'
																	fontWeight='500'
																	textTransform='uppercase'
																>
																	{formatCurrency(order.totalPrice)}
																</Typography>
															</TableCell>
															<TableCell align='right'>
																{['pending', 'paid'].includes(order.status) && (
																	<Button
																		variant='text'
																		color='error'
																		title={t('cancel')}
																		onClick={() =>
																			setOpenCancelOrder(order.orderId)
																		}
																		sx={{
																			borderRadius: '50%',
																			minWidth: '40px',
																			height: '40px',
																			p: 0,
																		}}
																	>
																		<CancelIcon />
																	</Button>
																)}

																<IconButton
																	color='info'
																	sx={{
																		width: '40px',
																		height: '40px',
																		p: 0,
																	}}
																>
																	<Link href={`/order/${order.orderId}`}>
																		<VisibilityIcon
																			color='secondary'
																			sx={{ mt: 0.8 }}
																		/>
																	</Link>
																</IconButton>
															</TableCell>
														</TableRow>
													))}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							)}
						</Grid>
					</Container>
				</Paper>
			</Grid>
			{openCancelOrder && (
				<Dialog
					fullScreen={false}
					open={!!openCancelOrder}
					onClose={() => {
						setOpenCancelOrder('');
					}}
					maxWidth='md'
					fullWidth={true}
					PaperProps={{
						sx: {
							borderRadius: '12px',
							padding: '0.8rem',
						},
					}}
				>
					<DialogContent
						sx={{
							padding: '1rem',
						}}
					>
						<Grid container rowSpacing={3}>
							<Grid item xs={12}>
								<Typography variant='ExplicationTextBold'>
									{t('cancelOrder')}
								</Typography>
								<Typography
									variant='ExplicationText'
									sx={{
										margin: '3px 0 20px 0',
									}}
								>
									{t('cancelOrderDescription')}
								</Typography>
							</Grid>
						</Grid>

						<Grid item md={12} xs={12}>
							<FormControl fullWidth>
								<TextField
									label={t('cancellationReason', { ns: 'common' })}
									id='reason'
									placeholder={t('cancellationReasonPlaceholder', {
										ns: 'common',
									})}
									fullWidth={false}
									name='reason'
									multiline
									rows={3}
									value={cancellationReason}
									onChange={(e) => setCancellationReason(e.target.value)}
								/>
							</FormControl>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button
							title={t('cancel')}
							onClick={(e) => {
								e.stopPropagation();
								setOpenCancelOrder('');
							}}
							variant='contained'
							color='error'
						>
							{t('close', { ns: 'common' })}
						</Button>
						<Button
							disabled={!cancellationReason || executing}
							title={t('accept', { ns: 'common' })}
							onClick={(e) => {
								e.stopPropagation();
								handleCancelOrder();
							}}
							color='secondary'
							variant='contained'
							sx={{
								mr: 1,
							}}
						>
							{executing ? (
								<>
									<CircularProgress size='19px' color='info' />{' '}
									{`${' '} ${t('canceling')}`}
								</>
							) : (
								t('accept', { ns: 'common' })
							)}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Grid>
	);
}
